<template>
  <div class="about-wrapper-views">
    <div v-if="htmlData" v-html="htmlData"/>
    <div v-else class="d-flex flex-column justify-content-center align-items-center w-100">
      <div class="mt-2">
        <EmptyIcon />
      </div>
      <h1 style="color: #000000" class="ml-2 text-center py-4">Coming Soon.</h1>
      <div style="color: #000000" class="ml-2 desc-empty-state text-center">Maaf konten tidak ditemukan, silahkan muat ulang halaman ini untuk beberapa saat kedepan.</div>
    </div>
  </div>
</template>
<script>

import EmptyIcon from '@/components/Icons/Empty.vue'

export default {
  components: {
    EmptyIcon,
  },
  name: 'dynamic-about',
  data: function () {
    return {
      htmlData: null,
    }
  },
  methods: {
    getHtmlPage(path) {
      this.$store.dispatch('meta/GENERATEPAGEABOUT', {
        path: path ?? this.$route.params.pathname,
      })
        .then(({ data }) => {
          this.htmlData = data
        })
        .catch(() => {
          this.htmlData = null
        })
    },
  },
  created() {
    this.getHtmlPage()
  },
  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler(value) {
        this.getHtmlPage(value.pathname)
      },
    },
  },
}
</script>
<style scoped lang='scss'>
.about-wrapper-views {
  width: 80%;
  margin: 0 auto;

  @media (max-width: 567px) {
    width: 95%;
  }
}
.desc-empty-state {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 400px;
}
</style>
